import { Hero } from "@ui/Hero/Hero";
import { AndroidButton } from "@ui/AndroidButton";
import { AppleButton } from "@ui/AppleButton";
import React from "react";

export function Page() {
    return (
        <>
            <Hero>
                <div className="mt-10 sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28">
                    <div className="sm:text-center lg:text-left">
                        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                            <span className="block xl:inline">Lezecký průvodce</span>{" "}
                            <span className="block text-primary-600 xl:inline">od lezců pro lezce</span>
                        </h1>
                        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                            Topografický průvodce, ve kterém najdete cesty zakreslené přímo ve fotografiích. Lezecký
                            deníček, přehledná mapa oblastí i offline režim.
                        </p>
                    </div>
                </div>
            </Hero>
            <div className="bg-gray-200 bg-opacity-25" id="download-app">
                <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                    <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                        <h2 className="max-w-md mx-auto text-3xl font-extrabold text-primary-600 text-center lg:max-w-xl lg:text-left">
                            Mobilní aplikace ke stažení
                        </h2>
                        <div className="flow-root self-center mt-8 lg:mt-0">
                            <div className="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
                                <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-4">
                                    <AndroidButton />
                                    <AppleButton />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative bg-white pt-16 pb-32 overflow-hidden">
                <div className="relative">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                        <div className="px-4 max-w-xl mx-auto sm:px-6 lg:pb-16 lg:pt-8 lg:max-w-none lg:mx-0 lg:px-0">
                            <div>
                                <div className="mt-6">
                                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                        Co je OpenTopo
                                    </h2>
                                    <p className="mt-4 text-lg text-gray-500">
                                        Opentopo je chytrý otevřený lezecký topoprůvodce do mobilu. V Opentopu najdete
                                        nejen fotografie stěn se zakreslenými cestami, ale také mapu s lokací
                                        jednotlivých věží či bouldrů a popis oblastí včetně přístupu a hlavních
                                        vlastností každé oblasti. To vše funguje nejen v online, ale i v offline režimu.
                                    </p>
                                    <div className="mt-6">
                                        <a
                                            href="/#download-app"
                                            className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700"
                                        >
                                            Stáhnout aplikaci
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {/*TODO <div className="mt-8 border-t border-gray-200 pt-6">
                                <blockquote>
                                    <div>
                                        <p className="text-base text-gray-500">
                                            &ldquo;Cras velit quis eros eget rhoncus lacus ultrices sed diam. Sit orci
                                            risus aenean curabitur donec aliquet. Mi venenatis in euismod ut.&rdquo;
                                        </p>
                                    </div>
                                </blockquote>
                            </div>*/}
                        </div>
                        <div className="mt-12 sm:mt-16 lg:mt-0">
                            <div className="flex justify-center items-center h-full">
                                <img
                                    className="w-60"
                                    src="https://opentopo.app/images/telefony-57.png"
                                    alt="Inbox user interface"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-24">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                        <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                            <div>
                                <div className="mt-6">
                                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                        Jak to funguje?
                                    </h2>
                                    <p className="mt-4 text-lg text-gray-500">
                                        Stačí stáhnout aplikaci do mobilu, vybrat oblast a vyrazit do skal! Díky GPS
                                        lokaci přesně víte kde zaparkovat a kam jít, popisy jednotlivých oblastí vám
                                        prozradí i orientaci skal, potřebné vybavení, nebo třeba to, jestli se v této
                                        oblasti bude líbit i dětem. Díky přehledně zakresleným cestám v reálných
                                        fotografiích pak už stačí pouze vybrat ten správný lezecký směr a vyrazit
                                        vzhůru!
                                    </p>
                                    <div className="mt-6">
                                        <a
                                            href="/#download-app"
                                            className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700"
                                        >
                                            Stáhnout aplikaci
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                            <div className="flex justify-center items-center h-full">
                                <img
                                    className="w-60"
                                    src="https://opentopo.app/images/telefony-58.png"
                                    alt="Customer profile user interface"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/** About as **/}
            <div className="bg-white" id="o-nas">
                <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
                    <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
                        <div className="space-y-5 sm:space-y-4">
                            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">O nás</h2>
                            <p className="text-xl text-gray-500">
                                Jsme tým nadšených lezců z Liberce a okolí. Rádi navštěvujeme lokální skalní oblasti a
                                zároveň objevujeme nové lezecké destinace. Právě tento zápal dal vzniknout aplikaci
                                OpenTopo. Podpora Libereckého kraje a spolupráce s Hanibalem pak umožňuje její rozvoj a
                                mapování nových oblastí.
                            </p>
                        </div>
                        <div className="lg:col-span-2">
                            <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:gap-x-8">
                                <li>
                                    <div className="flex items-center space-x-4 lg:space-x-6">
                                        <img
                                            className="w-16 h-16 rounded-full lg:w-20 lg:h-20"
                                            src="/images/vejvis.jpg"
                                            alt=""
                                        />
                                        <div className="font-medium text-lg leading-6 space-y-1">
                                            <h3>Petr Vejvoda</h3>
                                            <p className="text-primary-600" />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center space-x-4 lg:space-x-6">
                                        <img
                                            className="w-16 h-16 rounded-full lg:w-20 lg:h-20"
                                            src="/images/ondra.jpg"
                                            alt=""
                                        />
                                        <div className="font-medium text-lg leading-6 space-y-1">
                                            <h3>Ondřej Vacek</h3>
                                            <p className="text-primary-600" />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center space-x-4 lg:space-x-6">
                                        <img
                                            className="w-16 h-16 rounded-full lg:w-20 lg:h-20"
                                            src="/images/vondrej.jpg"
                                            alt=""
                                        />
                                        <div className="font-medium text-lg leading-6 space-y-1">
                                            <h3>Ondřej Horáček</h3>
                                            <p className="text-primary-600" />
                                        </div>
                                    </div>
                                </li>
                                {/*<li>
                                    <div className="flex items-center space-x-4 lg:space-x-6">
                                        <img
                                            className="w-16 h-16 rounded-full lg:w-20 lg:h-20"
                                            src="/images/ondra_s.jpg"
                                            alt=""
                                        />
                                        <div className="font-medium text-lg leading-6 space-y-1">
                                            <h3>Ondra Šnopl</h3>
                                            <p className="text-primary-600"></p>
                                        </div>
                                    </div>
                                </li>*/}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
